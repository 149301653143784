body {
  font-family: 'Open Sans', sans-serif;
  background-color: #023047;
  color: #fff;
}
img {
  max-width: 100%;
  height: auto;
}
header {
  width: 100%;
  height: 120px;
  display: block;
  background: #219ebc;
}
.loadingGear img {
  margin-top: 20px;
}
.logo {
  height: 100px;
  margin-top: 4px;
  margin-left: 10px;
}
ul.navigation {
  list-style: none;

  position: relative;
  max-width: 100%;
  float: right;
  top: 40px;
}
.nav-container {
  width: 100%;
  position: relative;
}
ul.navigation li {
  float: left;
  margin-right: 20px;
}
ul.navigation li a {
  font-size: 25px;
  color: #023047;
  font-style: italic;
  text-decoration: none;
  padding: 3px;
  border-right: 2px solid transparent;
}
ul.navigation li a:hover {
  font-weight: bold;
  text-decoration: underline;
  border: none;
}
ul.navigation li a.active {
  font-weight: bold;
  text-decoration: underline;
  color: #fb8500;
}

section {
  margin-top: 30px;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 300px;
  position: relative;
  margin-top: 100px;
}

.map-responsive iframe {
  right: 0;
  top: 0;
  height: 300px;
  width: 100%;
  position: absolute;
}

footer {
  width: 100%;
  height: 90px;
  background-color: #8ecae6;
  margin-top: 75px;
  text-align: center;
}
.footer span {
  position: relative;
  top: 30px;
}
.address {
  text-align: center;
}
h2 {
  display: inline-block;
  margin-bottom: 30px;
  font-weight: 600;
  font-style: italic;
  color: #fb8500;
}

.address span {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.address p {
  margin-bottom: 4px;
}
.address a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
}
.address a:hover {
  text-decoration: underline;
}
.hero {
  width: 100%;
  height: 400px;
  background: url('./assets/background.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
}
.hero h1 {
  font-size: 52px;
  font-weight: bold;
  font-style: italic;
}
.heroText {
  background-color: rgba(2, 48, 71, 0.3);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  top: 85px;
}
.heroText em {
  font-style: italic;
}

.grid-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  line-height: 0;
  grid-gap: 10px;
}
.grid-item {
  overflow: hidden;
  object-fit: cover;
}
.grid-item img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: ease-in-out 0.5s;
}
.grid-item:hover img {
  transform: scale(1.5);
}
h1 {
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}
.garaga {
  background: #fff;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #000;
}
.garaga p {
  width: 85%;
  background: #dedede;
  padding: 10px;
  border-radius: 4px;
}
h3 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}
.garagaLogo {
  padding-right: 30px;
  margin-top: 50px;
}
.required-text {
  background: #5998c5;

  display: inline-block;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
i.required {
  color: red;
  font-style: italic;
  font-weight: bold;
}
.contact-container {
  transition: max-height 0.5s ease-in-out;
}
.smallGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.smallItem img {
  height: 150px;
  object-fit: cover;
  border-radius: 20px;
  transition: ease-in-out 0.5s;
}

.smallItem img:hover {
  transform: rotate(5deg);
}
.serviceImg {
  border-radius: 20px;
}
section.service p {
  background: #219ebc;
  padding: 20px;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  ul.navigation li a {
    font-size: 17px;
  }
}
@media (max-width: 900px) {
  .smallGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .garagaContainer {
    display: none;
  }
  ul.navigation li a {
    font-size: 13px;
  }
  ul.navigation li {
    margin-right: 5px;
  }
}
@media (max-width: 400px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
